import styled from "styled-components";

import { Grid } from "../Grid";

export const NotificationsLayout = styled(Grid)`
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);

    @media (max-width: 767px) {
        grid-template-columns: 1fr;
    }
`;
