import { Select as MtSelect, SelectProps as MtSelectProps } from "@mantine/core";
import React from "react";
import styled from "styled-components";

import { FontSize, FontWeight } from "src/theme";
import type { InputOptionType } from "src/types";
import { DEFAULT_INPUT_WIDTH } from "../constants";
import { Text } from "../Text";

type SystemsSelectProps = Pick<MtSelectProps, "name" | "onBlur" | "onChange" | "value" | "error"> & {
    options: InputOptionType[];
    label?: string;
    width?: string;
};

export const Select = React.forwardRef<HTMLInputElement, SystemsSelectProps>(
    ({ name, onBlur, onChange, value, error, options, label, width = DEFAULT_INPUT_WIDTH }, ref) => (
        <Wrapper width={width}>
            <StyledSystemsSelect
                ref={ref}
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={error}
                data={options}
                label={
                    label ? (
                        <Text size={FontSize._14} weight={FontWeight._700}>
                            {label}
                        </Text>
                    ) : null
                }
            />
        </Wrapper>
    ),
);

const Wrapper = styled.div<Pick<SystemsSelectProps, "width">>`
    width: ${({ width }) => width};
`;

const StyledSystemsSelect = styled(MtSelect)``;
