import type { FC } from "react";

import { NoTableData } from "./NoTableData";

// @ts-ignore todo: types
export const TableBody: FC = ({ getTableBodyProps, rows, prepareRow, isLoading }) => {
    if (rows.length === 0 || isLoading) {
        return <NoTableData isLoading={isLoading} />;
    }

    return (
        <tbody {...getTableBodyProps()}>
            {/* @ts-ignore todo: types */}
            {rows.map((row) => {
                prepareRow(row);
                return (
                    <tr {...row.getRowProps()}>
                        {/* @ts-ignore todo: types */}
                        {row.cells.map((cell) => {
                            return (
                                <td
                                    {...cell.getCellProps()}
                                    style={{
                                        padding: "1rem",
                                    }}
                                >
                                    {cell.render("Cell")}
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    );
};
