import type { FC } from "react";

import { Button, ButtonVariant, Flex, Icon, Icons, Text } from "src/components";
import { useTranslation } from "src/translations";
import { DATE_FORMAT, getEndOfWeek, getOffsetDate, localeFormat, useAppDispatch, useAppSelector } from "src/utils";
import { setNextWeek, setPreviousWeek, setThisWeek } from "../actions";
import { getWeekOffset } from "../selectors";
import { useFirstDayOfWeek } from "../utils";

export const Controls: FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const firstDayOfWeek = useFirstDayOfWeek();
    const weekOffset = useAppSelector(getWeekOffset);

    const from = localeFormat(getOffsetDate(firstDayOfWeek, 0, weekOffset), DATE_FORMAT);
    const to = localeFormat(getEndOfWeek(getOffsetDate(firstDayOfWeek, 0, weekOffset)), DATE_FORMAT);

    return (
        <Flex alignItems="center" gap="1rem">
            <Button onClick={() => dispatch(setThisWeek())} variant={ButtonVariant.Outline}>
                {t("thisWeek")}
            </Button>
            <Flex>
                <Icon
                    icon={Icons.ArrowLeft}
                    onClick={() => dispatch(setPreviousWeek())}
                    height={30}
                    width={28}
                    alt={t("dashboard.previousWeek")}
                />
                <Icon
                    icon={Icons.ArrowRight}
                    onClick={() => dispatch(setNextWeek())}
                    height={30}
                    width={28}
                    alt={t("dashboard.nextWeek")}
                />
            </Flex>
            <Text>{`${from} - ${to}`}</Text>
        </Flex>
    );
};
