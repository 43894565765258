import type { FC } from "react";

import { Alert, Flex, Skeleton, Text } from "src/components";
import { Color, FontWeight } from "src/theme";
import { useTranslation } from "src/translations";
import { OutageType } from "src/types";

type OutageTypeProps = {
    type?: OutageType;
    isLoading?: boolean;
};

export const Type: FC<OutageTypeProps> = ({ type, isLoading }) => {
    const { t } = useTranslation();

    return (
        <Flex flexDirection="column" gap="0.5rem">
            <Text weight={FontWeight._700}>{t("outageDetail.type")}</Text>
            {isLoading ? (
                <Skeleton width="10rem" />
            ) : (
                <Flex alignItems="center" gap="0.5em">
                    <Alert
                        clickable={false}
                        type={type === OutageType.UnplannedOutage ? OutageType.UnplannedOutage : OutageType.PlannedOutage}
                    />
                    <Text color={Color.textPrimary}>{t(type === OutageType.UnplannedOutage ? "outage.unplanned" : "outage.planned")}</Text>
                </Flex>
            )}
        </Flex>
    );
};
