import type { FC } from "react";
import styled from "styled-components";

import { Color, FontSize } from "src/theme";
import { useTranslation } from "src/translations";
import { Text } from "../Text";
import { Tooltip } from "../Tooltip";

type IndicatorProps = {
    systemDown: boolean;
};

export const Indicator: FC<IndicatorProps> = ({ systemDown }) => {
    const { t } = useTranslation();

    return (
        <Tooltip
            content={
                <Text color={Color.white} size={FontSize._14}>
                    {t(systemDown ? "dashboard.systemDown" : "dashboard.systemUp")}
                </Text>
            }
        >
            <StyledIndicator systemDown={systemDown} />
        </Tooltip>
    );
};

const StyledIndicator = styled.div<IndicatorProps>`
    min-width: 12px;
    min-height: 12px;
    border-radius: ${({ theme }) => theme.borderRadius.full};
    background-color: ${({ theme, systemDown }) => (systemDown ? theme.color.red100 : theme.color.green)};
`;
