import { combineReducers, createReducer } from "@reduxjs/toolkit";

import type { DashboardState } from "src/types";
import { setNextWeek, setPreviousWeek, setThisWeek } from "./actions";

export const weekOffset = createReducer<number>(0, (builder) =>
    builder
        .addCase(setNextWeek, (state) => state + 1)
        .addCase(setPreviousWeek, (state) => state - 1)
        .addCase(setThisWeek, () => 0),
);

export const reducer = combineReducers<DashboardState>({
    weekOffset,
});
