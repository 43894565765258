import type { FC } from "react";
import styled from "styled-components";

import { createCssProperty } from "../../createCssProperty";
import type {
    AlignSelf,
    GridArea,
    GridColumn,
    GridColumnEnd,
    GridColumnStart,
    GridRow,
    GridRowEnd,
    GridRowStart,
    JustifySelf,
} from "../../types";

type GridElementProps = GridColumnStart &
    GridColumnEnd &
    GridRowStart &
    GridRowEnd &
    GridColumn &
    GridRow &
    GridArea &
    JustifySelf &
    AlignSelf;

const GridElement: FC<GridElementProps> = styled.div<GridElementProps>`
    ${({ gridColumnStart }) => createCssProperty("grid-column-start", gridColumnStart)}
    ${({ gridColumnEnd }) => createCssProperty("grid-column-end", gridColumnEnd)}
    ${({ gridRowStart }) => createCssProperty("grid-row-start", gridRowStart)}
    ${({ gridRowEnd }) => createCssProperty("grid-row-end", gridRowEnd)}
    ${({ gridColumn }) => createCssProperty("grid-column", gridColumn)}
    ${({ gridRow }) => createCssProperty("grid-row", gridRow)}
    ${({ gridArea }) => createCssProperty("grid-area", gridArea)}
    ${({ justifySelf }) => createCssProperty("justify-self", justifySelf)}
    ${({ alignSelf }) => createCssProperty("align-self", alignSelf)}
`;

export default GridElement;
