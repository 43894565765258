import { Radio as MtRadio, RadioGroup as MtRadioGroup, RadioGroupProps as MtRadioGroupProps } from "@mantine/core";
import React from "react";
import styled from "styled-components";

import { Color } from "src/theme";
import type { InputOptionType } from "src/types";

type RadioGroupProps = Pick<MtRadioGroupProps, "name" | "onBlur" | "onChange" | "value"> & {
    options: InputOptionType[];
};

export const RadioGroup = React.forwardRef<HTMLInputElement, RadioGroupProps>(({ name, onBlur, onChange, value, options }, ref) => (
    <StyledRadioGroup name={name} onBlur={onBlur} onChange={onChange} value={value} size="sm" ref={ref}>
        {options.map(({ label, value: radioValue }) => (
            <MtRadio
                key={radioValue}
                label={label}
                value={radioValue}
                style={{ borderColor: value === radioValue ? Color.azure : Color.gray100 }}
            />
        ))}
    </StyledRadioGroup>
));

const StyledRadioGroup = styled(MtRadioGroup)`
    .mantine-RadioGroup-radioWrapper {
        padding: 9px;
        border-width: 1px;
        border-style: solid;
        border-radius: ${({ theme }) => theme.borderRadius.input};
    }

    .mantine-RadioGroup-radio {
        border-color: ${({ theme }) => theme.color.gray100};

        :checked {
            background-color: ${({ theme }) => theme.color.azure};
            border-color: ${({ theme }) => theme.color.azure};
        }
    }
`;
