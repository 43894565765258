import type { FC } from "react";
import styled from "styled-components";

const Header: FC = ({ children }) => (
    <StyledHeader>
        <Wrapper>{children}</Wrapper>
    </StyledHeader>
);

const StyledHeader = styled.header`
    display: flex;
    justify-content: center;
    background-color: ${({ theme }) => theme.color.azure};
    padding: ${({ theme }) => theme.spacing.pagePadding};
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: ${({ theme }) => theme.spacing.contentMaxWidth};
`;

export default Header;
