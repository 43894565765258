import { Tooltip as MtTooltip } from "@mantine/core";
import type { FC, ReactNode } from "react";

type TooltipProps = {
    content: ReactNode;
};

export const Tooltip: FC<TooltipProps> = ({ children, content }) => (
    <MtTooltip label={content} withArrow>
        {children}
    </MtTooltip>
);
