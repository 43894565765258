import type { TableCommonProps } from "react-table";
import styled from "styled-components";

export const Table = styled.table<TableCommonProps>`
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;

    th,
    td {

        /* Each cell should grow equally */
        /* But "collapsed" cells should be as small as possible */
        &.collapse {
            width: 0.0000000001%;
        }
    }

    tr:first-child td {
        border-top: none;
    }

    tr:last-child td {
        border-bottom: none;
    }

    tr {
        td {
          border: 1px solid ${({ theme }) => theme.color.gray25};
        }
        td:first-child {
            border-bottom: none;
            border-left: none;
        }

        td:last-child {
            border-right: none;
            border-bottom: none;
        }
    }

    tr:first-child {
        th:first-child {
            border-top-left-radius: ${({ theme }) => theme.borderRadius._5};
        }

        th:last-child {
            border-top-right-radius: ${({ theme }) => theme.borderRadius._5};
        }
    }

    tr:last-child {
        td:first-child {
            border-bottom-left-radius: ${({ theme }) => theme.borderRadius._5};
        }

        td:last-child {
            border-bottom-right-radius: ${({ theme }) => theme.borderRadius._5};
        }
    }
}
`;
