import type { InputOptionType, SubscriptionViewDto, SystemDto } from "src/types";
import { NOTIFICATIONS_TAG, SUBSCRIPTIONS_TAG } from "./constants";
import { rootApi } from "./rootApi";
import type { UpdateNotificationPayload, UpdateSubscriptionPayload } from "./types";

const subscriptionApi = rootApi.enhanceEndpoints({ addTagTypes: [NOTIFICATIONS_TAG, SUBSCRIPTIONS_TAG] });

const subscriptionEndpoints = subscriptionApi.injectEndpoints({
    endpoints: (build) => ({
        getMySubscriptions: build.query<SubscriptionViewDto, void>({
            query: () => "/subscription/mine",
            providesTags: [SUBSCRIPTIONS_TAG],
        }),
        getSpecificSystems: build.query<InputOptionType[], void>({
            query: () => "/subscription/specific-systems",
            transformResponse: (response: SystemDto[]) =>
                response.map((option) => ({ label: option.jiraSystemName, value: option.jiraSystemId })),
        }),
        updateSubscription: build.mutation<void, UpdateSubscriptionPayload>({
            query: ({ csrfToken, ...body }) => ({
                url: "/subscription",
                method: "POST",
                body,
                headers: {
                    "X-CSRF-TOKEN": csrfToken,
                },
            }),
            invalidatesTags: [SUBSCRIPTIONS_TAG],
        }),
        updateNotification: build.mutation<void, UpdateNotificationPayload>({
            query: ({ csrfToken, ...body }) => ({
                url: "/subscription/notification",
                method: "POST",
                body,
                headers: {
                    "X-CSRF-TOKEN": csrfToken,
                },
            }),
            invalidatesTags: [NOTIFICATIONS_TAG],
        }),
        extendSubscription: build.mutation<void, string>({
            query: (csrfToken) => ({
                url: "/subscription/extend",
                method: "POST",
                headers: {
                    "X-CSRF-TOKEN": csrfToken,
                },
            }),
            invalidatesTags: [SUBSCRIPTIONS_TAG],
        }),
    }),
});

export const {
    useGetMySubscriptionsQuery,
    useGetSpecificSystemsQuery,
    useUpdateSubscriptionMutation,
    useUpdateNotificationMutation,
    useExtendSubscriptionMutation,
} = subscriptionEndpoints;
