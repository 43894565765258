import type { FC } from "react";
import styled from "styled-components";

export const TableLayout: FC = ({ children }) => (
    <Layout>
        <Wrapper>{children}</Wrapper>
    </Layout>
);

const Layout = styled.div`
    display: block;
    max-width: 100%;
`;

const Wrapper = styled.div`
    display: block;
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    border: ${({ theme }) => `0.5px solid ${theme.color.gray25}`};
    border-radius: ${({ theme }) => theme.borderRadius._5};
    box-shadow: 0 4px 25px rgba(148, 163, 187, 0.32);
`;
