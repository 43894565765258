import type { CsrfToken } from "src/types";
import { rootApi } from "./rootApi";
import type { LoginPayload } from "./types";

export const authApi = rootApi.injectEndpoints({
    endpoints: (build) => ({
        getCsrfToken: build.query<CsrfToken, void>({
            query: () => "csrf",
            keepUnusedDataFor: Infinity,
        }),
        login: build.mutation<void, LoginPayload>({
            query: ({ csrfToken, ...body }) => {
                return {
                    url: "/auth/login",
                    method: "POST",
                    body,
                    headers: {
                        "X-CSRF-TOKEN": csrfToken,
                    },
                };
            },
        }),
        logout: build.mutation<void, string>({
            query: (csrfToken) => ({
                url: "/logout",
                method: "POST",
                headers: {
                    "X-CSRF-TOKEN": csrfToken,
                },
            }),
        }),
    }),
});

export const { useGetCsrfTokenQuery, useLazyGetCsrfTokenQuery, useLoginMutation, useLogoutMutation } = authApi;
