import type { FC } from "react";
import styled from "styled-components";

import { createCssProperty } from "../../createCssProperty";
import type { AlignContent, AlignItems, ColumnGap, FlexDirection, FlexWrap, Gap, JustifyContent, RowGap } from "../../types";

type FlexProps = FlexWrap & FlexDirection & AlignItems & JustifyContent & AlignContent & Gap & RowGap & ColumnGap;

const Flex: FC<FlexProps> = styled.div<FlexProps>`
    display: flex;

    ${({ flexDirection }) => createCssProperty("flex-direction", flexDirection)};
    ${({ flexWrap }) => createCssProperty("flex-wrap", flexWrap)};
    ${({ justifyContent }) => createCssProperty("justify-content", justifyContent)};
    ${({ alignItems }) => createCssProperty("align-items", alignItems)};
    ${({ alignContent }) => createCssProperty("align-content", alignContent)};
    ${({ gap }) => createCssProperty("gap", gap)};
    ${({ rowGap }) => createCssProperty("row-gap", rowGap)};
    ${({ columnGap }) => createCssProperty("column-gap", columnGap)};
`;

export default Flex;
