import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AuthProvider } from "src/auth";
import { AccessDenied, Footer, Header, Main, PageLayout } from "src/components";
import { dashboard } from "./dashboard";
import { HeaderContent } from "./layout";
import { outageDetail } from "./outageDetail";
import { routes } from "./routes";
import { subscriptions } from "./subscriptions";

export const AppRouter = () => (
    <BrowserRouter>
        <PageLayout>
            <Header>
                <HeaderContent />
            </Header>
            <AuthProvider>
                <Main>
                    <Routes>
                        <Route path={routes.dashboard.path} element={<dashboard.Container />} />
                        <Route path={routes.outageDetail.path} element={<outageDetail.Container />} />
                        <Route path={routes.subscriptions.path} element={<subscriptions.Container />} />

                        <Route path={routes.accessDenied.path} element={<AccessDenied />} />
                        <Route path="*" element={<h1>404</h1>} />
                    </Routes>
                </Main>
            </AuthProvider>
            <Footer />
        </PageLayout>
    </BrowserRouter>
);
