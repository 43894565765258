import type { FC } from "react";
import styled from "styled-components";

import { Color } from "src/theme";
import { useTranslation } from "src/translations";
import { Icon, Icons } from "../Icon";
import { Flex } from "../Layouts";
import { Loader } from "../Loader";
import { Text } from "../Text";

export const NoTableData: FC<{ isLoading: boolean }> = ({ isLoading }) => {
    const { t } = useTranslation();

    return (
        <StyledTableBody>
            <tr>
                <td colSpan={8}>
                    <Flex justifyContent="center">
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <Flex flexDirection="column" alignItems="center" gap="1rem">
                                <Icon icon={Icons.Info} height={35} width={35} />
                                <Text color={Color.gray200}>{t("dashboard.noOutages")}</Text>
                            </Flex>
                        )}
                    </Flex>
                </td>
            </tr>
        </StyledTableBody>
    );
};

const StyledTableBody = styled.tbody`
    height: 14rem;
`;
