import type { FC } from "react";

import { Flex, Heading } from "src/components";
import { useTranslation } from "src/translations";
import { Controls, OutagesTable, TableAnnotation } from "./containers";

export type DashboardProps = Readonly<{}>;

export const Dashboard: FC<DashboardProps> = () => {
    const { t } = useTranslation();

    return (
        <>
            <Heading level={1}>{t("dashboard.title")}</Heading>
            <Flex flexDirection="column" gap="1.5rem">
                <Controls />
                <OutagesTable />
                <TableAnnotation />
            </Flex>
        </>
    );
};
