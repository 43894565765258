import { FC, useCallback } from "react";
import { Link } from "react-router-dom";

import { useGetAuthenticatedUserQuery, useGetConfigQuery } from "src/api";
import { routes } from "src/app/routes";
import logoutIcon from "src/assets/logout.svg";
import profileIcon from "src/assets/profile.svg";
import logo from "src/assets/uniqa_logo.svg";
import { Flex, Icon, UnstyledButton } from "src/components";
import { navigateExternal } from "src/utils";
import { useLogout } from "./query";

export const HeaderContent: FC = () => {
    const { isError } = useGetAuthenticatedUserQuery();

    return (
        <>
            <Link to={isError ? routes.accessDenied.path : routes.dashboard.path}>
                <Icon icon={logo} height={80} width={220} alt="UNIQA logo" withAnimation />
            </Link>
            <Flex gap="1.5rem">
                <Link to={isError ? routes.accessDenied.path : routes.subscriptions.path}>
                    <Icon icon={profileIcon} height={80} width={40} alt="Profil" withAnimation />
                </Link>
                <Logout />
            </Flex>
        </>
    );
};

const Logout = () => {
    const { data: config } = useGetConfigQuery();
    const [logout] = useLogout();

    const { protocol, host } = window.location;
    const redirectUri = `${protocol}//${host}`;

    const onLogout = useCallback(async () => {
        await logout();
        navigateExternal(config!.keycloak.logoutUri, {
            redirect_uri: redirectUri,
        });
    }, [config, logout, redirectUri]);

    return (
        <UnstyledButton onClick={() => onLogout()}>
            <Icon icon={logoutIcon} height={80} width={32} alt="Odhlásit" withAnimation />
        </UnstyledButton>
    );
};
