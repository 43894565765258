import type { FC } from "react";
import styled from "styled-components";

export const PageLayout = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

export const Main: FC = ({ children }) => (
    <StyledMain>
        <Wrapper>{children}</Wrapper>
    </StyledMain>
);

const StyledMain = styled.main`
    display: flex;
    justify-content: center;
    flex: 1;
    padding: ${({ theme }) => theme.spacing.pagePadding};
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: ${({ theme }) => theme.spacing.contentMaxWidth};
    gap: 2.5rem;
    padding: 3rem 0;
`;
