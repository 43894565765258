import type { TFunction } from "react-i18next";

import type { InputOptionType } from "src/types";

// re export it for future improvements
export { Trans, useTranslation } from "react-i18next";

export const enumToLocalizedOptions = (t: TFunction, enumPrefix: string, inputEnum: Record<string, string>): InputOptionType[] => {
    return Object.keys(inputEnum).map((enumValue) => ({
        label: t(`enum.${enumPrefix}.${inputEnum[enumValue]}`),
        value: inputEnum[enumValue],
    }));
};
