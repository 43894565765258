import type { FC } from "react";
import styled from "styled-components";

import { Color, FontSize } from "src/theme";
import { useTranslation } from "src/translations";
import { getCurrentYear } from "src/utils";
import { Text } from "../Text";

const Footer: FC = () => {
    const { t } = useTranslation();

    return (
        <StyledFooter>
            <Wrapper>
                <Text color={Color.textSecondary} size={FontSize._14}>
                    {t("footer.content", { date: getCurrentYear() })}
                </Text>
            </Wrapper>
        </StyledFooter>
    );
};

const StyledFooter = styled.footer`
    display: flex;
    justify-content: center;
    height: 3.5rem;
    background-color: ${({ theme }) => theme.color.gray25};
    padding: ${({ theme }) => theme.spacing.pagePadding};
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: ${({ theme }) => theme.spacing.contentMaxWidth};
`;

export default Footer;
