import type { FC } from "react";
import styled from "styled-components";

type HeadingLevel = 1 | 2 | 3 | 4;

type HeadingProps = Readonly<{
    level?: HeadingLevel;
    textAlign?: "left" | "center" | "right";
    className?: string;
}>;

export const Heading: FC<HeadingProps> = ({ children, level = 1, textAlign = "left", className }) => (
    <StyledHeading as={`h${level}`} level={level} textAlign={textAlign} className={className}>
        {children}
    </StyledHeading>
);

const getLevelBasedValue = (level: HeadingLevel, values: [string, string, string]) => values[level - 1];

const StyledHeading = styled.h1<Required<Pick<HeadingProps, "level" | "textAlign">>>`
    display: flex;
    justify-content: ${({ textAlign }) => {
        switch (textAlign) {
            case "right":
                return "flex-end";
            case "center":
                return "center";
            default:
                return "flex-start";
        }
    }};
    margin: 0;
    font-size: ${({ level }) => getLevelBasedValue(level, ["2.125rem", "2.125rem", "1.25rem"])};
    text-align: ${({ textAlign }) => textAlign};
    color: ${({ theme }) => theme.color.textPrimary};
    word-break: break-word;
`;
