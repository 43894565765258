/**
 * Type for additional info for saga error. This is same type as ErrorInfo from onError handler from SagaMiddlewareOptions. ErrorInfo is not exported
 * from redux-saga so instead this type is defined.
 */
export type SagaErrorInfo = Readonly<{ sagaStack: string }>;

/**
 * Type of function for handling of errors from root saga.
 */
export type SagaErrorListener = (error: Error, errorInfo: SagaErrorInfo) => void;

/**
 * Type of function for handling of errors from root reducer. Type of error is unknown, because this is type from catch().
 * Listener itself may narrow it down by type guards if needed.
 */
export type ReducerErrorListener = (error: unknown) => void;

export enum ErrorSource {
    component = "Component",
    saga = "Saga",
    reducer = "Reducer",
}
