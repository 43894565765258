import { FullScreenLayout, Loader } from "src/components";
import { useAuth } from "./useAuth";

type AuthProviderProps = {
    children: JSX.Element;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
    const { loading } = useAuth();

    if (loading) {
        return (
            <FullScreenLayout>
                <Loader />
            </FullScreenLayout>
        );
    }

    return children;
};

export default AuthProvider;
