import { useParams } from "react-router-dom";

import type { EmptyObject } from "src/types";
import { getEmptyObject } from "src/utils";

const OUTAGE = "outage";
const OUTAGE_ID = "outageId";

const SUBSCRIPTIONS = "odbery";
const ACCESS_DENIED = "pristup-odepren";

type Path = string;

type PathParams = Readonly<Record<string, string>>;

type OutageDetailPath = Readonly<{
    [OUTAGE_ID]: string;
}>;

type Route<P extends PathParams> = Readonly<{
    path: Path;
    fillPathParams: (params?: P) => Path;
    useParams: () => P;
}>;

const fillOutageDetailPathParams = ({ outageId }: OutageDetailPath) => `/${OUTAGE}/${outageId}`;

/**
 * Generate path description. Just add ":" before parameters names and use them in fillPathParamsFunc.
 * @param filPathParamsFunc - path for fill parameters could be used, just add ":" before route parameters names
 * @param params - parameters of the route.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const generatePath = <P extends PathParams>(filPathParamsFunc: (params: P) => Path, params: P): Path =>
    filPathParamsFunc(Object.entries(params).reduce((result, [key, param]) => Object.assign(result, { [key]: `:${param}` }), {} as P));

/**
 * Creates simple route without path params.
 * @param path Route path.
 */
const createSimpleRoute = (path: Path): Route<EmptyObject> => ({
    path,
    fillPathParams: () => path,
    useParams: getEmptyObject,
});

export const routes = {
    dashboard: createSimpleRoute(`/`),
    outageDetail: {
        path: generatePath(fillOutageDetailPathParams, { [OUTAGE_ID]: OUTAGE_ID }),
        fillPathParams: fillOutageDetailPathParams,
        useParams: () => useParams<OutageDetailPath>(),
    },
    subscriptions: createSimpleRoute(`/${SUBSCRIPTIONS}`),
    accessDenied: createSimpleRoute(`/${ACCESS_DENIED}`),
} as const;
