import { Skeleton as MtSkeleton } from "@mantine/core";
import type { FC } from "react";

type SkeletonProps = {
    width?: string;
    height?: string;
};

export const Skeleton: FC<SkeletonProps> = ({ height = "1.2rem", width = "20rem" }) => (
    <MtSkeleton height={height} width={width} radius="lg" />
);
