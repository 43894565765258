/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 * @enum {string}
 */
export enum Category {
    PlannedOutage = "PLANNED_OUTAGE",
    UnplannedOutage = "UNPLANNED_OUTAGE",
}
/**
 *
 * @export
 * @interface ConfigDto
 */
export interface ConfigDto {
    /**
     *
     * @type {KeycloakConfigDto}
     * @memberof ConfigDto
     */
    keycloak: KeycloakConfigDto;
    /**
     *
     * @type {string}
     * @memberof ConfigDto
     */
    currentDateTime: string;
}
/**
 *
 * @export
 * @interface ConfiguredGroupAppDto
 */
export interface ConfiguredGroupAppDto {
    /**
     *
     * @type {string}
     * @memberof ConfiguredGroupAppDto
     */
    groupId: string;
    /**
     *
     * @type {string}
     * @memberof ConfiguredGroupAppDto
     */
    groupName: string;
    /**
     *
     * @type {Array<ConfiguredSystemDto>}
     * @memberof ConfiguredGroupAppDto
     */
    systems: Array<ConfiguredSystemDto>;
}
/**
 *
 * @export
 * @interface ConfiguredSystemDto
 */
export interface ConfiguredSystemDto {
    /**
     *
     * @type {string}
     * @memberof ConfiguredSystemDto
     */
    jiraSystemId: string;
    /**
     *
     * @type {string}
     * @memberof ConfiguredSystemDto
     */
    jiraSystemName: string;
}
/**
 *
 * @export
 * @interface CreateSubscriptionDto
 */
export interface CreateSubscriptionDto {
    /**
     *
     * @type {SystemSubscriptionType}
     * @memberof CreateSubscriptionDto
     */
    systemSubscriptionType: SystemSubscriptionType;
    /**
     *
     * @type {Array<string>}
     * @memberof CreateSubscriptionDto
     */
    specificJiraSystemIds?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof CreateSubscriptionDto
     */
    groupAppId?: string;
}
/**
 *
 * @export
 * @interface CsrfToken
 */
export interface CsrfToken {
    /**
     *
     * @type {string}
     * @memberof CsrfToken
     */
    parameterName?: string;
    /**
     *
     * @type {string}
     * @memberof CsrfToken
     */
    token?: string;
    /**
     *
     * @type {string}
     * @memberof CsrfToken
     */
    headerName?: string;
}
/**
 *
 * @export
 * @interface ErrorResponseDto
 */
export interface ErrorResponseDto {
    /**
     *
     * @type {string}
     * @memberof ErrorResponseDto
     */
    message?: string;
    /**
     *
     * @type {number}
     * @memberof ErrorResponseDto
     */
    code: number;
    /**
     *
     * @type {string}
     * @memberof ErrorResponseDto
     */
    status?: ErrorResponseDtoStatusEnum;
    /**
     *
     * @type {string}
     * @memberof ErrorResponseDto
     */
    url?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ErrorResponseDtoStatusEnum {
    _100Continue = "100 CONTINUE",
    _101SwitchingProtocols = "101 SWITCHING_PROTOCOLS",
    _102Processing = "102 PROCESSING",
    _103EarlyHints = "103 EARLY_HINTS",
    _103Checkpoint = "103 CHECKPOINT",
    _200Ok = "200 OK",
    _201Created = "201 CREATED",
    _202Accepted = "202 ACCEPTED",
    _203NonAuthoritativeInformation = "203 NON_AUTHORITATIVE_INFORMATION",
    _204NoContent = "204 NO_CONTENT",
    _205ResetContent = "205 RESET_CONTENT",
    _206PartialContent = "206 PARTIAL_CONTENT",
    _207MultiStatus = "207 MULTI_STATUS",
    _208AlreadyReported = "208 ALREADY_REPORTED",
    _226ImUsed = "226 IM_USED",
    _300MultipleChoices = "300 MULTIPLE_CHOICES",
    _301MovedPermanently = "301 MOVED_PERMANENTLY",
    _302Found = "302 FOUND",
    _302MovedTemporarily = "302 MOVED_TEMPORARILY",
    _303SeeOther = "303 SEE_OTHER",
    _304NotModified = "304 NOT_MODIFIED",
    _305UseProxy = "305 USE_PROXY",
    _307TemporaryRedirect = "307 TEMPORARY_REDIRECT",
    _308PermanentRedirect = "308 PERMANENT_REDIRECT",
    _400BadRequest = "400 BAD_REQUEST",
    _401Unauthorized = "401 UNAUTHORIZED",
    _402PaymentRequired = "402 PAYMENT_REQUIRED",
    _403Forbidden = "403 FORBIDDEN",
    _404NotFound = "404 NOT_FOUND",
    _405MethodNotAllowed = "405 METHOD_NOT_ALLOWED",
    _406NotAcceptable = "406 NOT_ACCEPTABLE",
    _407ProxyAuthenticationRequired = "407 PROXY_AUTHENTICATION_REQUIRED",
    _408RequestTimeout = "408 REQUEST_TIMEOUT",
    _409Conflict = "409 CONFLICT",
    _410Gone = "410 GONE",
    _411LengthRequired = "411 LENGTH_REQUIRED",
    _412PreconditionFailed = "412 PRECONDITION_FAILED",
    _413PayloadTooLarge = "413 PAYLOAD_TOO_LARGE",
    _413RequestEntityTooLarge = "413 REQUEST_ENTITY_TOO_LARGE",
    _414UriTooLong = "414 URI_TOO_LONG",
    _414RequestUriTooLong = "414 REQUEST_URI_TOO_LONG",
    _415UnsupportedMediaType = "415 UNSUPPORTED_MEDIA_TYPE",
    _416RequestedRangeNotSatisfiable = "416 REQUESTED_RANGE_NOT_SATISFIABLE",
    _417ExpectationFailed = "417 EXPECTATION_FAILED",
    _418IAmATeapot = "418 I_AM_A_TEAPOT",
    _419InsufficientSpaceOnResource = "419 INSUFFICIENT_SPACE_ON_RESOURCE",
    _420MethodFailure = "420 METHOD_FAILURE",
    _421DestinationLocked = "421 DESTINATION_LOCKED",
    _422UnprocessableEntity = "422 UNPROCESSABLE_ENTITY",
    _423Locked = "423 LOCKED",
    _424FailedDependency = "424 FAILED_DEPENDENCY",
    _425TooEarly = "425 TOO_EARLY",
    _426UpgradeRequired = "426 UPGRADE_REQUIRED",
    _428PreconditionRequired = "428 PRECONDITION_REQUIRED",
    _429TooManyRequests = "429 TOO_MANY_REQUESTS",
    _431RequestHeaderFieldsTooLarge = "431 REQUEST_HEADER_FIELDS_TOO_LARGE",
    _451UnavailableForLegalReasons = "451 UNAVAILABLE_FOR_LEGAL_REASONS",
    _500InternalServerError = "500 INTERNAL_SERVER_ERROR",
    _501NotImplemented = "501 NOT_IMPLEMENTED",
    _502BadGateway = "502 BAD_GATEWAY",
    _503ServiceUnavailable = "503 SERVICE_UNAVAILABLE",
    _504GatewayTimeout = "504 GATEWAY_TIMEOUT",
    _505HttpVersionNotSupported = "505 HTTP_VERSION_NOT_SUPPORTED",
    _506VariantAlsoNegotiates = "506 VARIANT_ALSO_NEGOTIATES",
    _507InsufficientStorage = "507 INSUFFICIENT_STORAGE",
    _508LoopDetected = "508 LOOP_DETECTED",
    _509BandwidthLimitExceeded = "509 BANDWIDTH_LIMIT_EXCEEDED",
    _510NotExtended = "510 NOT_EXTENDED",
    _511NetworkAuthenticationRequired = "511 NETWORK_AUTHENTICATION_REQUIRED",
}
/**
 *
 * @export
 * @enum {string}
 */
export enum IssueState {
    Open = "OPEN",
    Finished = "FINISHED",
}
/**
 *
 * @export
 * @interface JiraIssueFieldsDto
 */
export interface JiraIssueFieldsDto {
    /**
     *
     * @type {string}
     * @memberof JiraIssueFieldsDto
     */
    summary: string;
    /**
     *
     * @type {string}
     * @memberof JiraIssueFieldsDto
     */
    description: string;
    /**
     *
     * @type {string}
     * @memberof JiraIssueFieldsDto
     */
    customfield10574?: string;
    /**
     *
     * @type {string}
     * @memberof JiraIssueFieldsDto
     */
    customfield10575?: string;
    /**
     *
     * @type {string}
     * @memberof JiraIssueFieldsDto
     */
    customfield10321?: string;
    /**
     *
     * @type {string}
     * @memberof JiraIssueFieldsDto
     */
    customfield10322: string;
    /**
     *
     * @type {string}
     * @memberof JiraIssueFieldsDto
     */
    customfield10323: string;
    /**
     *
     * @type {string}
     * @memberof JiraIssueFieldsDto
     */
    customfield10324?: string;
    /**
     *
     * @type {JiraIssueTypeDto}
     * @memberof JiraIssueFieldsDto
     */
    issuetype: JiraIssueTypeDto;
    /**
     *
     * @type {JiraStatusDto}
     * @memberof JiraIssueFieldsDto
     */
    status: JiraStatusDto;
    /**
     *
     * @type {Array<SystemDto>}
     * @memberof JiraIssueFieldsDto
     */
    mainOutageSystems: Array<SystemDto>;
}
/**
 *
 * @export
 * @interface JiraIssueTypeDto
 */
export interface JiraIssueTypeDto {
    /**
     *
     * @type {number}
     * @memberof JiraIssueTypeDto
     */
    id: number;
}
/**
 *
 * @export
 * @interface JiraOutageDto
 */
export interface JiraOutageDto {
    /**
     *
     * @type {string}
     * @memberof JiraOutageDto
     */
    id: string;
    /**
     *
     * @type {string}
     * @memberof JiraOutageDto
     */
    key: string;
    /**
     *
     * @type {JiraIssueFieldsDto}
     * @memberof JiraOutageDto
     */
    fields: JiraIssueFieldsDto;
}
/**
 *
 * @export
 * @interface JiraStatusDto
 */
export interface JiraStatusDto {
    /**
     *
     * @type {number}
     * @memberof JiraStatusDto
     */
    id: number;
}
/**
 *
 * @export
 * @interface KeycloakConfigDto
 */
export interface KeycloakConfigDto {
    /**
     *
     * @type {string}
     * @memberof KeycloakConfigDto
     */
    authUri: string;
    /**
     *
     * @type {string}
     * @memberof KeycloakConfigDto
     */
    logoutUri: string;
    /**
     *
     * @type {string}
     * @memberof KeycloakConfigDto
     */
    clientId: string;
}
/**
 *
 * @export
 * @interface LoginDto
 */
export interface LoginDto {
    /**
     *
     * @type {string}
     * @memberof LoginDto
     */
    code: string;
    /**
     *
     * @type {string}
     * @memberof LoginDto
     */
    redirectUri: string;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum NotificationChannel {
    Sms = "SMS",
    Email = "EMAIL",
}
/**
 *
 * @export
 * @interface NotificationConfigDto
 */
export interface NotificationConfigDto {
    /**
     *
     * @type {Category}
     * @memberof NotificationConfigDto
     */
    subscriptionType: Category;
    /**
     *
     * @type {Array<NotificationChannel>}
     * @memberof NotificationConfigDto
     */
    notificationChannel: Array<NotificationChannel>;
}
/**
 *
 * @export
 * @interface NotificationMethodsDto
 */
export interface NotificationMethodsDto {
    /**
     *
     * @type {string}
     * @memberof NotificationMethodsDto
     */
    email?: string;
    /**
     *
     * @type {boolean}
     * @memberof NotificationMethodsDto
     */
    emailSubscriptionActive?: boolean;
    /**
     *
     * @type {string}
     * @memberof NotificationMethodsDto
     */
    phoneNumber?: string;
    /**
     *
     * @type {boolean}
     * @memberof NotificationMethodsDto
     */
    smsSubscriptionActive?: boolean;
    /**
     *
     * @type {Array<NotificationConfigDto>}
     * @memberof NotificationMethodsDto
     */
    notificationConfig: Array<NotificationConfigDto>;
}
/**
 *
 * @export
 * @interface OutageDto
 */
export interface OutageDto {
    /**
     *
     * @type {number}
     * @memberof OutageDto
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof OutageDto
     */
    summary: string;
    /**
     *
     * @type {string}
     * @memberof OutageDto
     */
    description: string;
    /**
     *
     * @type {string}
     * @memberof OutageDto
     */
    start: string;
    /**
     *
     * @type {string}
     * @memberof OutageDto
     */
    expectedEnd: string;
    /**
     *
     * @type {string}
     * @memberof OutageDto
     */
    end?: string;
    /**
     *
     * @type {Category}
     * @memberof OutageDto
     */
    category: Category;
    /**
     *
     * @type {IssueState}
     * @memberof OutageDto
     */
    issueState: IssueState;
    /**
     *
     * @type {Array<string>}
     * @memberof OutageDto
     */
    mainSystems?: Array<string>;
    /**
     *
     * @type {Array<string>}
     * @memberof OutageDto
     */
    otherSystems?: Array<string>;
}
/**
 *
 * @export
 * @interface SubscriptionDto
 */
export interface SubscriptionDto {
    /**
     *
     * @type {number}
     * @memberof SubscriptionDto
     */
    id?: number;
    /**
     *
     * @type {SystemSubscriptionType}
     * @memberof SubscriptionDto
     */
    type: SystemSubscriptionType;
    /**
     *
     * @type {boolean}
     * @memberof SubscriptionDto
     */
    active: boolean;
}
/**
 *
 * @export
 * @interface SubscriptionViewDto
 */
export interface SubscriptionViewDto {
    /**
     *
     * @type {number}
     * @memberof SubscriptionViewDto
     */
    id?: number;
    /**
     *
     * @type {SystemSubscriptionType}
     * @memberof SubscriptionViewDto
     */
    type: SystemSubscriptionType;
    /**
     *
     * @type {Array<string>}
     * @memberof SubscriptionViewDto
     */
    systemIds?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof SubscriptionViewDto
     */
    groupedAppId?: string;
}
/**
 *
 * @export
 * @interface SystemDto
 */
export interface SystemDto {
    /**
     *
     * @type {string}
     * @memberof SystemDto
     */
    jiraSystemId: string;
    /**
     *
     * @type {string}
     * @memberof SystemDto
     */
    jiraSystemName: string;
}
/**
 *
 * @export
 * @interface SystemOutagesDto
 */
export interface SystemOutagesDto {
    /**
     *
     * @type {string}
     * @memberof SystemOutagesDto
     */
    name: string;
    /**
     *
     * @type {boolean}
     * @memberof SystemOutagesDto
     */
    systemDown: boolean;
    /**
     *
     * @type {Array<OutageDto>}
     * @memberof SystemOutagesDto
     */
    outages: Array<OutageDto>;
}
/**
 *
 * @export
 * @enum {string}
 */
export enum SystemSubscriptionType {
    All = "All",
    Specified = "Specified",
}
/**
 *
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     *
     * @type {number}
     * @memberof UserDto
     */
    id?: number;
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    username: string;
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    email?: string;
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    phoneNumber?: string;
    /**
     *
     * @type {boolean}
     * @memberof UserDto
     */
    emailSubscription?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof UserDto
     */
    smsSubscription?: boolean;
    /**
     *
     * @type {string}
     * @memberof UserDto
     */
    subscriptionExpiration?: string;
    /**
     *
     * @type {NotificationMethodsDto}
     * @memberof UserDto
     */
    notificationMethods: NotificationMethodsDto;
}
