import { isSameDay } from "date-fns";
import { FC, memo, useMemo } from "react";
import { useTable } from "react-table";

import { useGetOutagesForWeekQuery } from "src/api";
import { Table, TableBody, TableHeader, TableLayout } from "src/components";
import { useTranslation } from "src/translations";
import { BE_DATE_FORMAT, getOffsetDate, localeFormat, useAppSelector } from "src/utils";
import { getWeekOffset } from "../selectors";
import { useFirstDayOfWeek, useToday } from "../utils";
import { DateCell, OutageCell, SystemCell } from "./Cells";

export const OutagesTable: FC = () => {
    const { t } = useTranslation();
    const weekOffset = useAppSelector(getWeekOffset);
    const today = useToday();
    const firstDayOfWeek = useFirstDayOfWeek();
    const { data: outages, currentData, isFetching } = useGetOutagesForWeekQuery(weekOffset, { pollingInterval: 5000 });

    const isFetchingNewEntity = outages === undefined || (isFetching && currentData !== outages);

    const columns = useMemo(() => {
        const systemNameCol = {
            accessor: "name",
            Header: t("outage.systemName"),
            // @ts-ignore
            // eslint-disable-next-line react/no-unstable-nested-components
            Cell: memo(({ row }) => <SystemCell system={row.original} />),
            minWidth: 200,
        };
        const dayCols = [...Array(7).keys()].map((day, index) => {
            const offsetDate = getOffsetDate(firstDayOfWeek, index, weekOffset);
            const isHighlighted = isSameDay(today, offsetDate);

            return {
                // @ts-ignore
                accessor: (row) => row.outages[localeFormat(offsetDate, BE_DATE_FORMAT)],
                id: day.toString(),
                // eslint-disable-next-line react/no-unstable-nested-components
                Header: memo(() => <DateCell date={offsetDate} isHighlighted={isHighlighted} />),
                // @ts-ignore
                // eslint-disable-next-line react/no-unstable-nested-components
                Cell: memo(({ value }) => <OutageCell outages={value} />),
                minWidth: 90,
                isHighlighted,
            };
        });

        return [systemNameCol, ...dayCols];
    }, [t, firstDayOfWeek, weekOffset, today]);

    // @ts-ignore
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: outages || [] });

    return (
        <TableLayout>
            <Table {...getTableProps()}>
                {/* @ts-ignore todo: types */}
                <TableHeader headerGroups={headerGroups} />
                {/* @ts-ignore todo: types */}
                <TableBody getTableBodyProps={getTableBodyProps} rows={rows} prepareRow={prepareRow} isLoading={isFetchingNewEntity} />
            </Table>
        </TableLayout>
    );
};
