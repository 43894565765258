import type { FC } from "react";

import { useTranslation } from "src/translations";
import { FullScreenLayout } from "../Layouts";
import { Text } from "../Text";

export const AccessDenied: FC = () => {
    const { t } = useTranslation();

    return (
        <FullScreenLayout>
            <Text>{t("accessDenied")}</Text>
        </FullScreenLayout>
    );
};
