import type { OutageDto } from "src/types";
import { rootApi } from "./rootApi";

export const outagesApi = rootApi.injectEndpoints({
    endpoints: (build) => ({
        getOutageDetail: build.query<OutageDto, string>({
            query: (id) => ({ url: `outages/${id}` }),
        }),
    }),
});

export const { useGetOutageDetailQuery } = outagesApi;
