import ArrowLeft from "src/assets/icons/arrow-left.svg";
import ArrowRight from "src/assets/icons/arrow-right.svg";
import Info from "src/assets/icons/circle-info.svg";
import Warning from "src/assets/icons/warning.svg";

export const Icons = {
    ArrowLeft,
    ArrowRight,
    Info,
    Warning,
} as const;
