import type { FC } from "react";
import type { HeaderGroup } from "react-table";
import styled, { css } from "styled-components";

type TableHeaderProps = {
    headerGroups: HeaderGroup[];
};

type ColumnHeaderProps = {
    collapse?: boolean;
    maxWidth?: number;
    minWidth?: number;
    isHighlighted?: boolean;
};

// @ts-ignore todo: types
const getHeaderProps = (props, { column }): TableHeaderProps & ColumnHeaderProps => ({
    ...props,
    className: column.collapse ? "collapse" : "",
    maxWidth: column.maxWidth,
    minWidth: column.minWidth,
    isHighlighted: column.isHighlighted,
});

export const TableHeader: FC<TableHeaderProps> = ({ headerGroups }) => (
    <thead>
        {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                    // @ts-ignore todo: types
                    <Th {...column.getHeaderProps(getHeaderProps)}>{column.render("Header")}</Th>
                ))}
            </tr>
        ))}
    </thead>
);

const Th = styled.th<ColumnHeaderProps>`
    background-color: ${({ theme, isHighlighted }) => (isHighlighted ? theme.color.saphire : theme.color.azure)};
    padding: 1rem;
    box-sizing: content-box;
    text-align: center;
    vertical-align: center;
    color: ${({ theme }) => theme.color.gray25};
    font-weight: 400;
    ${({ maxWidth }) =>
        maxWidth &&
        css`
            max-width: ${maxWidth}px;
        `}
    ${({ minWidth }) =>
        minWidth &&
        css`
            min-width: ${minWidth}px;
        `}
`;
