import type { FC } from "react";

import { Flex, Indicator, OutageAlert, Text } from "src/components";
import { Color, FontSize, FontWeight } from "src/theme";
import type { NormalisedSystemOutagesDto, OutageDto } from "src/types";
import { DATE_FORMAT, localeFormat } from "src/utils";

export const DateCell: FC<{ date: Date; isHighlighted: boolean }> = ({ date, isHighlighted }) => {
    const fontWeight = isHighlighted ? FontWeight._700 : FontWeight._400;

    return (
        <Flex flexDirection="column" alignItems="center" gap="0.2rem">
            <Text color={Color.white} weight={fontWeight}>
                {localeFormat(date, DATE_FORMAT)}
            </Text>
            <Text color={Color.white} size={FontSize._14} weight={fontWeight}>
                {localeFormat(date, "eeee")}
            </Text>
        </Flex>
    );
};

export const SystemCell: FC<{ system: NormalisedSystemOutagesDto }> = ({ system }) => {
    const { name, systemDown } = system;

    return (
        <Flex alignItems="center" gap="0.7rem">
            <Indicator systemDown={systemDown} />
            <Text>{name}</Text>
        </Flex>
    );
};

// @ts-ignore todo: types
export const OutageCell: FC<{ outages: OutageDto[] }> = ({ outages }) => {
    if (!outages) {
        return null;
    }

    return (
        <Flex justifyContent="center" flexWrap="wrap" gap="0.5rem">
            {outages.map((outage) => (
                <OutageAlert key={outage.id} outage={outage} />
            ))}
        </Flex>
    );
};
