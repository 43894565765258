import { typeCheckAppModule } from "src/utils";
import { NAME } from "./constants";
import { Dashboard } from "./Dashboard";
import { reducer } from "./reducer";

export const dashboard = typeCheckAppModule({
    NAME,
    Container: Dashboard,
    reducer,
});
