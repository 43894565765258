import { yupResolver } from "@hookform/resolvers/yup";
import type { TFunction } from "i18next";
import { FC, useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { cleanArray } from "utils/fn";
import * as yup from "yup";

import { useGetAuthenticatedUserQuery, useGetCsrfTokenQuery, useUpdateNotificationMutation } from "src/api";
import { Button, Flex, Heading, NotificationsLayout, Switch, TextInput } from "src/components";
import { showSuccessNotification } from "src/notifications";
import { useTranslation } from "src/translations";
import { NormalisedNotificationMethods, NotificationChannel, NotificationMethodsDto, OutageType } from "src/types";

const createSchema = (t: TFunction) =>
    yup.object({
        email: yup
            .string()
            .email(t("validation.email.invalid"))
            .when(["plannedOutageEmailNotificationsActive", "unplannedOutageEmailNotificationsActive"], {
                is: eitherActive,
                then: yup.string().required(t("validation.email.required")),
            }),
        phoneNumber: yup.string().when(["plannedOutageSmsNotificationsActive", "unplannedOutageSmsNotificationsActive"], {
            is: eitherActive,
            then: yup.string().matches(/^\+[1-9]\d{1,14}$/, t("validation.phoneNumber.invalid")),
        }),
        plannedOutageSmsNotificationsActive: yup.boolean(),
        plannedOutageEmailNotificationsActive: yup.boolean(),
        unplannedOutageSmsNotificationsActive: yup.boolean(),
        unplannedOutageEmailNotificationsActive: yup.boolean(),
    });
const eitherActive = (a: boolean, b: boolean) => a || b;

export const Notifications: FC = () => {
    const { t } = useTranslation();

    const { data: user } = useGetAuthenticatedUserQuery();
    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty, errors },
    } = useForm<NormalisedNotificationMethods>({
        resolver: yupResolver(createSchema(t)),
    });

    useEffect(() => {
        const notificationMethods = user?.notificationMethods;
        reset({
            email: notificationMethods?.email || "",
            phoneNumber: notificationMethods?.phoneNumber || "",
            plannedOutageSmsNotificationsActive: notificationMethods?.plannedOutageSmsNotificationsActive ?? false,
            plannedOutageEmailNotificationsActive: notificationMethods?.plannedOutageEmailNotificationsActive ?? true,
            unplannedOutageSmsNotificationsActive: notificationMethods?.unplannedOutageSmsNotificationsActive ?? false,
            unplannedOutageEmailNotificationsActive: notificationMethods?.unplannedOutageEmailNotificationsActive ?? true,
        });
    }, [user, reset]);

    const { data: csrfTokenResponse } = useGetCsrfTokenQuery();
    const [submit, { isLoading: isSubmitting }] = useUpdateNotificationMutation();
    const onSubmit = useCallback(
        async (formData: NormalisedNotificationMethods) => {
            try {
                const normalizedData = mergeNormalisedNotificationMethods(formData);
                await submit({ csrfToken: csrfTokenResponse!.token!, ...(normalizedData as NotificationMethodsDto) }).unwrap();
                showSuccessNotification({
                    title: t("subscriptions.notifications.successTitle"),
                    message: t("subscriptions.notifications.successMessage"),
                });
            } catch (e) {
                // handled by global error handling
            }
        },
        [csrfTokenResponse, submit, t],
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Flex flexDirection="column" gap="1.5rem" alignItems="flex-start">
                <Heading level={3}>{t("subscriptions.notifications.title")}</Heading>
                <NotificationsLayout>
                    <Controller
                        name="email"
                        control={control}
                        render={({ field }) => <TextInput {...field} label={t("email")} error={errors.email?.message} />}
                    />
                    <Controller
                        name="phoneNumber"
                        control={control}
                        render={({ field }) => <TextInput {...field} label={t("phone")} error={errors.phoneNumber?.message} />}
                    />
                    <Flex flexDirection="column" gap="0.5rem">
                        <Heading level={4}>{t("subscriptions.notifications.plannedOutages")}</Heading>
                        <Controller
                            name="plannedOutageSmsNotificationsActive"
                            control={control}
                            render={({ field }) => <Switch {...field} label={t("subscriptions.notifications.sms")} />}
                        />
                        <Controller
                            name="plannedOutageEmailNotificationsActive"
                            control={control}
                            render={({ field }) => <Switch {...field} label={t("subscriptions.notifications.email")} />}
                        />
                    </Flex>
                    <Flex flexDirection="column" gap="0.5rem">
                        <Heading level={4}>{t("subscriptions.notifications.unplannedOutages")}</Heading>
                        <Controller
                            name="unplannedOutageSmsNotificationsActive"
                            control={control}
                            render={({ field }) => <Switch {...field} label={t("subscriptions.notifications.sms")} />}
                        />
                        <Controller
                            name="unplannedOutageEmailNotificationsActive"
                            control={control}
                            render={({ field }) => <Switch {...field} label={t("subscriptions.notifications.email")} />}
                        />
                    </Flex>
                </NotificationsLayout>
                <Button type="submit" disabled={!isDirty} loading={isSubmitting}>
                    {t("save")}
                </Button>
            </Flex>
        </form>
    );
};

const mergeNormalisedNotificationMethods = (normalisedNotificationMethods: NormalisedNotificationMethods) => ({
    email: normalisedNotificationMethods.email || null,
    phoneNumber: normalisedNotificationMethods.phoneNumber || null,
    notificationConfig: [
        {
            subscriptionType: OutageType.PlannedOutage,
            notificationChannel: cleanArray([
                normalisedNotificationMethods.plannedOutageEmailNotificationsActive && NotificationChannel.Email,
                normalisedNotificationMethods.plannedOutageSmsNotificationsActive && NotificationChannel.Sms,
            ]),
        },
        {
            subscriptionType: OutageType.UnplannedOutage,
            notificationChannel: cleanArray([
                normalisedNotificationMethods.unplannedOutageEmailNotificationsActive && NotificationChannel.Email,
                normalisedNotificationMethods.unplannedOutageSmsNotificationsActive && NotificationChannel.Sms,
            ]),
        },
    ],
});
