import type { ConfigDto } from "src/types";
import { rootApi } from "./rootApi";

export const configApi = rootApi.injectEndpoints({
    endpoints: (build) => ({
        getConfig: build.query<ConfigDto, void>({
            query: () => "config",
        }),
    }),
});

export const { useGetConfigQuery, useLazyGetConfigQuery } = configApi;
