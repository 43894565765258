import { useMemo } from "react";

import { useGetConfigQuery } from "src/api";
import { getStartOfWeek } from "src/utils";

export const useToday = () => {
    const { data } = useGetConfigQuery();
    return useMemo(() => (data ? new Date(data.currentDateTime) : new Date()), [data]);
};

export const useFirstDayOfWeek = () => {
    const { data } = useGetConfigQuery();
    return useMemo(() => getStartOfWeek(data ? new Date(data.currentDateTime) : new Date()), [data]);
};
