import { configureStore, isRejectedWithValue, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";

import { rootApi } from "src/api";
import { GlobalErrorEvents } from "src/errorHandling";
import { showErrorNotification } from "src/notifications";
import { EMPTY_OBJECT, isDevelopment } from "src/utils";
import { getRootReducer } from "./reducer";

/**
 * Handles global errors from RTK api calls
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        if (action.payload.status === 400 || action.payload.status === 500) {
            showErrorNotification({
                title: "Operace se nezdařila",
                message: "Prosím zkuste to znovu nebo se obraťte na Centrální helpdesk.",
            });
        }
    }

    return next(action);
};

/**
 * Creates redux store with root reducer and saga middleware and runs root saga.
 * Sets up top level error handling for root reducer and root saga.
 */
const createStore = () => {
    const rootReducer = getRootReducer();

    const errorHandlingReducer: typeof rootReducer = (state, action) => {
        try {
            return rootReducer(state, action);
        } catch (error) {
            GlobalErrorEvents.triggerReducerErrorEvent(error);
            /* Error at this place is unrecoverable, app cannot be used anymore and ErrorPage is displayed.
             * For this reason we can return previous state or empty object and type cast it. */
            return (state ?? EMPTY_OBJECT) as StoreState;
        }
    };

    const store = configureStore({
        reducer: errorHandlingReducer,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                immutableCheck: false,
            })
                .prepend(rootApi.middleware)
                .prepend(rtkQueryErrorLogger),
        devTools: isDevelopment(),
    });

    return store;
};

/**
 * Application store with redux-saga middleware.
 * Use as prop for react-redux Provider component.
 */
export const store = createStore();

export type StoreState = ReturnType<typeof store.getState>;
export type StoreDispatch = typeof store.dispatch;
