import type { FC } from "react";

import { Heading, SubscriptionsLayout } from "src/components";
import { useTranslation } from "src/translations";
import { Notifications, SubscriptionSettings } from "./containers";

export const Subscriptions: FC = () => {
    const { t } = useTranslation();

    return (
        <>
            <Heading level={2}>{t("subscriptions.title")}</Heading>
            <SubscriptionsLayout>
                <Notifications />
                <SubscriptionSettings />
            </SubscriptionsLayout>
        </>
    );
};
