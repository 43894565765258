import { eachDayOfInterval } from "date-fns";
import mergeWith from "lodash.mergewith";

import type { DateOutageRecord, NormalisedSystemOutagesDto, SystemOutagesDto } from "src/types";
import { dateToString } from "src/utils";
import { SUBSCRIPTIONS_TAG } from "./constants";
import { rootApi } from "./rootApi";
import { outagesMerger } from "./utils";

const systemsApi = rootApi.enhanceEndpoints({ addTagTypes: [SUBSCRIPTIONS_TAG] });

const systemsEndpoints = systemsApi.injectEndpoints({
    endpoints: (build) => ({
        getOutagesForWeek: build.query<NormalisedSystemOutagesDto[], number>({
            query: (weekOffset) => ({
                url: `systems/weekly/${weekOffset}`,
            }),
            providesTags: [SUBSCRIPTIONS_TAG],
            transformResponse: (response: SystemOutagesDto[]) =>
                response.map((system) => ({
                    ...system,
                    outages: system.outages.reduce((outagesAcc, outage) => {
                        const { start, expectedEnd, end } = outage;

                        // create outage for each day in the interval between start and end date
                        const eachDate = eachDayOfInterval({ start: new Date(start), end: new Date(end || expectedEnd) })
                            .map(dateToString)
                            .reduce((datesAcc, date) => ({ ...datesAcc, [date]: [outage] }), {});

                        return mergeWith(outagesAcc, eachDate, outagesMerger);
                    }, {} as DateOutageRecord),
                })),
        }),
    }),
});

export const { useGetOutagesForWeekQuery } = systemsEndpoints;
