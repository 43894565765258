import type { FC } from "react";

import { Flex, Text } from "src/components";
import { FontWeight } from "src/theme";

type LabelValuePairProps = {
    label: string;
    value?: string;
    isLoading?: boolean;
};

export const LabelValuePair: FC<LabelValuePairProps> = ({ label, value, isLoading }) => (
    <Flex flexDirection="column" gap="0.3rem">
        <Text weight={FontWeight._700}>{label}</Text>
        <Text isLoading={isLoading} skeletonWidth="10rem">
            {value}
        </Text>
    </Flex>
);
