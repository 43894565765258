import type { FC } from "react";
import styled from "styled-components";

export const OutageDetailLayout: FC = ({ children }) => (
    <Wrapper>
        <Content>{children}</Content>
    </Wrapper>
);

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 768px;
    gap: 2.5rem;

    @media (max-width: 767px) {
        max-width: 100%;
    }
`;
