import type { FC } from "react";

import { Alert, Flex, Text } from "src/components";
import { Color, FontSize } from "src/theme";
import { useTranslation } from "src/translations";
import { OutageType } from "src/types";

export const TableAnnotation: FC = () => {
    const { t } = useTranslation();

    return (
        <Flex justifyContent="flex-end" gap="1.5rem">
            <Flex alignItems="center" gap="0.5em">
                <Alert clickable={false} type={OutageType.PlannedOutage} />
                <Text size={FontSize._14} color={Color.yellow100}>
                    {t("outage.planned")}
                </Text>
            </Flex>
            <Flex alignItems="center" gap="0.5em">
                <Alert clickable={false} type={OutageType.UnplannedOutage} />
                <Text size={FontSize._14} color={Color.red100}>
                    {t("outage.unplanned")}
                </Text>
            </Flex>
        </Flex>
    );
};
