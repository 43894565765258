import { useCallback, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useLazyGetAuthenticatedUserQuery, useLazyGetConfigQuery, useLazyGetCsrfTokenQuery, useLoginMutation } from "src/api";
import { routes } from "src/app/routes";
import { navigateExternal, useComponentDidMount } from "src/utils";

export const useAuth = () => {
    const { href } = window.location;
    const redirectUri = href.split("?").shift();

    const loading = useRef(true);

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();

    const [getCsrfToken] = useLazyGetCsrfTokenQuery();
    const [login] = useLoginMutation();
    const [getAuthedUser, authedUserResult] = useLazyGetAuthenticatedUserQuery();
    const [getConfig] = useLazyGetConfigQuery();

    const hasCode = searchParams.has("code");

    const tryLogin = useCallback(async () => {
        try {
            const tokenResponse = await getCsrfToken().unwrap();
            await login({ code: searchParams.get("code")!, redirectUri: redirectUri!, csrfToken: tokenResponse.token! }).unwrap();

            // clear keycloak params from url
            searchParams.delete("session_state");
            searchParams.delete("code");
            setSearchParams(searchParams);

            await getAuthedUser().unwrap();
        } catch (e) {
            if ((e as Response).status === 403) {
                loading.current = false;
                navigate(routes.accessDenied.path);
            }
        }
    }, [getAuthedUser, getCsrfToken, login, navigate, redirectUri, searchParams, setSearchParams]);

    const redirectToKeycloak = useCallback(async () => {
        try {
            await getAuthedUser().unwrap();
        } catch (e) {
            const config = await getConfig().unwrap();
            const { keycloak } = config;
            const { authUri, clientId } = keycloak;

            navigateExternal(authUri, {
                client_id: clientId,
                redirect_uri: redirectUri,
                response_type: "code",
                scope: "openid",
                enable_kerberos: true,
            });
        }
    }, [getAuthedUser, getConfig, redirectUri]);

    useComponentDidMount(() => {
        if (hasCode) {
            tryLogin();
        } else {
            redirectToKeycloak();
        }
    });

    const { status, isError } = authedUserResult;
    if (status === "fulfilled" && !isError) {
        loading.current = false;
    }

    return { loading: loading.current };
};
