import type { TextInputProps as MtTextInputProps } from "@mantine/core";
import { TextInput as MtTextInput } from "@mantine/core";
import React from "react";
import styled from "styled-components";

import { FontSize, FontWeight } from "src/theme";
import { DEFAULT_INPUT_WIDTH } from "../constants";
import { Text } from "../Text";

type TextInputProps = Pick<MtTextInputProps, "name" | "onBlur" | "onChange" | "value" | "error"> & {
    label: string;
    width?: string;
};

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
    ({ name, onBlur, onChange, value = "", error, label, width = DEFAULT_INPUT_WIDTH }, ref) => (
        <Wrapper width={width}>
            <StyledTextInput
                ref={ref}
                label={
                    <Text size={FontSize._14} weight={FontWeight._700}>
                        {label}
                    </Text>
                }
                name={name}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                error={error}
            />
        </Wrapper>
    ),
);

const Wrapper = styled.div<Pick<TextInputProps, "width">>`
    width: ${({ width }) => width};
`;

const StyledTextInput = styled(MtTextInput)`
    .mantine-TextInput-defaultVariant {
        border-color: ${({ theme }) => theme.color.gray100};
        color: ${({ theme }) => theme.color.textPrimary};
        :focus:focus-within {
            border-color: ${({ theme }) => theme.color.azure};
        }
    }
`;
