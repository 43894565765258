import { useCallback } from "react";

import { useGetCsrfTokenQuery, useLogoutMutation } from "src/api";

export const useLogout = () => {
    const { data: csrfTokenResponse } = useGetCsrfTokenQuery();
    const [logoutTrigger] = useLogoutMutation();

    const logout = useCallback(async () => {
        await logoutTrigger(csrfTokenResponse!.token!);
    }, [csrfTokenResponse, logoutTrigger]);

    return [logout];
};
