import type { FC } from "react";

import { Badge, BadgeType, Flex, Skeleton, Text } from "src/components";
import { FontWeight } from "src/theme";
import { useTranslation } from "src/translations";
import { OutageState } from "src/types";

type StatusProps = {
    outageState?: OutageState;
    isLoading?: boolean;
};

export const Status: FC<StatusProps> = ({ outageState, isLoading }) => {
    const { t } = useTranslation();

    return (
        <Flex flexDirection="column" gap="0.5rem">
            <Text weight={FontWeight._700}>{t("outageDetail.status")}</Text>
            {isLoading ? (
                <Skeleton width="10rem" />
            ) : (
                <Badge
                    type={outageState === OutageState.Open ? BadgeType.warning : BadgeType.neutral}
                    text={t(outageState === OutageState.Open ? "outage.open" : "outage.finished")}
                />
            )}
        </Flex>
    );
};
