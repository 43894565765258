import type { DefaultTheme } from "styled-components";

import { BorderRadius } from "./borderRadius";
import { Color } from "./color";
import { FontSize } from "./fontSize";
import { FontWeight } from "./fontWeight";
import { Spacing } from "./spacing";

export { BorderRadius } from "./borderRadius";
export { Color } from "./color";
export { FontSize } from "./fontSize";
export { FontWeight } from "./fontWeight";
export { Spacing } from "./spacing";

export const theme: DefaultTheme = {
    borderRadius: BorderRadius,
    color: Color,
    fontSize: FontSize,
    fontWeight: FontWeight,
    spacing: Spacing,
};
