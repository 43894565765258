import { Button as MtButton } from "@mantine/core";
import type { FC } from "react";
import styled from "styled-components";

export enum ButtonVariant {
    Filled = "filled",
    Outline = "outline",
}

type ButtonProps = Readonly<{
    type?: "button" | "reset" | "submit";
    onClick?: () => void;
    variant?: ButtonVariant;
    disabled?: boolean;
    loading?: boolean;
}>;

export const Button: FC<ButtonProps> = ({
    children,
    onClick,
    type = "button",
    variant = ButtonVariant.Filled,
    disabled = false,
    loading = false,
}) => (
    // @ts-ignore todo: types
    <StyledButton onClick={onClick} type={type} variant={variant} disabled={disabled} loading={loading} loaderPosition="right">
        {children}
    </StyledButton>
);

const StyledButton = styled(MtButton)<Pick<ButtonProps, "disabled">>`
    &.mantine-Button-filled {
        border-color: ${({ theme }) => theme.color.azure};
        background-color: ${({ theme }) => theme.color.azure};

        :hover {
            border-color: ${({ theme, disabled }) => !disabled && theme.color.blue200};
            background-color: ${({ theme, disabled }) => !disabled && theme.color.blue200};
        }
    }
    &.mantine-Button-outline {
        color: ${({ theme }) => theme.color.azure};
        border-color: ${({ theme }) => theme.color.gray100};

        :hover {
            background-color: ${({ theme, disabled }) => !disabled && theme.color.blue10};
        }
    }
`;
