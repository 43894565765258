import type { FC } from "react";
import { Control, Controller } from "react-hook-form";

import { useGetSpecificSystemsQuery } from "src/api";
import { SystemsSelect } from "src/components";
import type { CreateSubscriptionDto } from "src/types";

type SystemsProps = {
    control: Control<CreateSubscriptionDto>;
    error?: string;
};

export const Systems: FC<SystemsProps> = ({ control, error }) => {
    const { data } = useGetSpecificSystemsQuery();

    return (
        <Controller
            name="specificJiraSystemIds"
            control={control}
            render={({ field }) => <SystemsSelect {...field} options={data || []} error={error} />}
        />
    );
};
