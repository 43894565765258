import styled from "styled-components";

export enum OutagePeriodAreas {
    start = "start",
    expectedEnd = "expectedEnd",
    end = "end",
}

export const OutagePeriodLayout = styled.div<{ withGap: boolean }>`
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 300px));
    row-gap: ${({ withGap }) => withGap && "1.5rem"};
    grid-template-areas:
        "${OutagePeriodAreas.start} ${OutagePeriodAreas.expectedEnd}"
        "${OutagePeriodAreas.end} .";
`;
