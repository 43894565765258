import type { FC } from "react";
import styled from "styled-components";

type LinkButtonProps = {
    onClick: () => void;
};

export const LinkButton: FC<LinkButtonProps> = ({ children, onClick }) => (
    <StyledLink type="button" onClick={onClick}>
        {children}
    </StyledLink>
);

const StyledLink = styled.button`
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    color: ${({ theme }) => theme.color.azure};
`;
