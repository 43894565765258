import type { NormalisedNotificationMethods, UserDto } from "src/types";
import { NotificationChannel, OutageType } from "src/types";
import { NOTIFICATIONS_TAG, SUBSCRIPTIONS_TAG } from "./constants";
import { rootApi } from "./rootApi";

const usersApi = rootApi.enhanceEndpoints({ addTagTypes: [NOTIFICATIONS_TAG, SUBSCRIPTIONS_TAG] });

const usersEndpoints = usersApi.injectEndpoints({
    endpoints: (build) => ({
        getAuthenticatedUser: build.query<
            Omit<UserDto, "notificationMethods"> & { notificationMethods: NormalisedNotificationMethods },
            void
        >({
            query: () => ({
                url: "users/authenticated-user",
            }),
            providesTags: [NOTIFICATIONS_TAG, SUBSCRIPTIONS_TAG],
            transformResponse: (user: UserDto) => {
                const normalizedNotificationMethods: NormalisedNotificationMethods = {
                    email: user.notificationMethods.email,
                    phoneNumber: user.notificationMethods.phoneNumber,
                    plannedOutageSmsNotificationsActive: false,
                    plannedOutageEmailNotificationsActive: false,
                    unplannedOutageSmsNotificationsActive: false,
                    unplannedOutageEmailNotificationsActive: false,
                };

                user.notificationMethods.notificationConfig.forEach(({ subscriptionType: category, notificationChannel }) => {
                    notificationChannel.forEach((channel) => {
                        switch (true) {
                            case category === OutageType.PlannedOutage && channel === NotificationChannel.Sms:
                                normalizedNotificationMethods.plannedOutageSmsNotificationsActive = true;
                                break;
                            case category === OutageType.PlannedOutage && channel === NotificationChannel.Email:
                                normalizedNotificationMethods.plannedOutageEmailNotificationsActive = true;
                                break;
                            case category === OutageType.UnplannedOutage && channel === NotificationChannel.Sms:
                                normalizedNotificationMethods.unplannedOutageSmsNotificationsActive = true;
                                break;
                            case category === OutageType.UnplannedOutage && channel === NotificationChannel.Email:
                                normalizedNotificationMethods.unplannedOutageEmailNotificationsActive = true;
                                break;
                            default:
                                throw new Error(`Unknown combination of category: ${category} and channel: ${channel}`);
                        }
                    });
                });

                return {
                    ...user,
                    notificationMethods: normalizedNotificationMethods,
                };
            },
        }),
    }),
});

export const { useGetAuthenticatedUserQuery, useLazyGetAuthenticatedUserQuery } = usersEndpoints;
