// imports to setup polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";

import { NotificationsProvider } from "@mantine/notifications";
import { StrictMode } from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ThemeProvider } from "styled-components";

import { AppRouter } from "src/app";
import { GlobalStyles } from "src/components";
import { ErrorBoundary } from "src/errorHandling";
import { store } from "src/setup";
import { theme } from "src/theme";
import { initTranslations } from "src/translations";
import { isDevelopment, printAppVersion } from "src/utils";

initTranslations();

if (isDevelopment()) {
    printAppVersion();
}

render(
    <StrictMode>
        <ErrorBoundary>
            <ThemeProvider theme={theme}>
                <GlobalStyles />

                <Provider store={store}>
                    <NotificationsProvider position="top-right">
                        <AppRouter />
                    </NotificationsProvider>
                </Provider>
            </ThemeProvider>
        </ErrorBoundary>
    </StrictMode>,
    document.getElementById("app"),
);
