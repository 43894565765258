import styled from "styled-components";

export enum OutageInfoAreas {
    status = "status",
    type = "type",
    description = "description",
}

export const OutageInfoLayout = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(200px, 300px));
    row-gap: 1.5rem;
    grid-template-areas:
        "${OutageInfoAreas.status} ${OutageInfoAreas.type}"
        "${OutageInfoAreas.description} ${OutageInfoAreas.description}";
`;
