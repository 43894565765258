import { yupResolver } from "@hookform/resolvers/yup";
import type { TFunction } from "i18next";
import type { FC } from "react";
import { useCallback, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";

import { useGetCsrfTokenQuery, useGetMySubscriptionsQuery, useUpdateSubscriptionMutation } from "src/api";
import { Button, Flex, Heading, RadioGroup } from "src/components";
import { showSuccessNotification } from "src/notifications";
import { enumToLocalizedOptions, useTranslation } from "src/translations";
import { CreateSubscriptionDto, SystemSubscriptionType } from "src/types";
import { SYSTEM_SUBSCRIPTION_TYPE_FIELD } from "../constants";
import { ExtendSubscription } from "./ExtendSubscription";
import { Systems } from "./Systems";

const createSchema = (t: TFunction) =>
    yup.object({
        systemSubscriptionType: yup.string(),
        specificJiraSystemIds: yup.array().when("systemSubscriptionType", {
            is: SystemSubscriptionType.Specified,
            then: yup.array().min(1, t("validation.jiraSystems.required")),
        }),
    });

export const SubscriptionSettings: FC = () => {
    const { t } = useTranslation();

    const { data: mySub } = useGetMySubscriptionsQuery();
    const {
        control,
        handleSubmit,
        watch,
        reset,
        formState: { errors, isDirty },
    } = useForm<CreateSubscriptionDto>({
        resolver: yupResolver(createSchema(t)),
    });

    useEffect(() => {
        reset({
            // @ts-ignore
            systemSubscriptionType: mySub?.type || "",
            specificJiraSystemIds: mySub?.systemIds || [],
            groupAppId: mySub?.groupedAppId || "",
        });
    }, [mySub, reset]);

    const { data: csrfTokenResponse } = useGetCsrfTokenQuery();
    const [submit, { isLoading: isSubmitting }] = useUpdateSubscriptionMutation();
    const onSubmit = useCallback(
        async (formData: CreateSubscriptionDto) => {
            try {
                await submit({ csrfToken: csrfTokenResponse!.token!, ...formData }).unwrap();
                showSuccessNotification({
                    title: t("subscriptions.successTitle"),
                    message: t("subscriptions.successMessage"),
                });
            } catch (e) {
                // handled by global error handling
            }
        },
        [csrfTokenResponse, submit, t],
    );

    const subscriptionType = watch(SYSTEM_SUBSCRIPTION_TYPE_FIELD);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Flex flexDirection="column" gap="1.5rem">
                <Heading level={3}>{t("subscriptions.active")}</Heading>
                <Flex flexDirection="column" gap="1rem">
                    <ExtendSubscription />
                    <Controller
                        name={SYSTEM_SUBSCRIPTION_TYPE_FIELD}
                        control={control}
                        render={({ field }) => (
                            <RadioGroup {...field} options={enumToLocalizedOptions(t, "systemSubscriptionType", SystemSubscriptionType)} />
                        )}
                    />
                    {subscriptionType === SystemSubscriptionType.Specified && (
                        // @ts-ignore
                        <Systems control={control} error={errors.specificJiraSystemIds?.message} />
                    )}
                </Flex>
                <Flex>
                    <Button type="submit" disabled={!isDirty} loading={isSubmitting}>
                        {t("subscriptions.changeSubscriptions")}
                    </Button>
                </Flex>
            </Flex>
        </form>
    );
};
