import { Switch as MtSwitch, SwitchProps as MtSwitchProps } from "@mantine/core";
import React from "react";
import styled from "styled-components";

import { FontSize, FontWeight } from "src/theme";
import { Text } from "../Text";

type SwitchProps = Pick<MtSwitchProps, "name" | "onBlur" | "onChange"> & { label: string; value: boolean };

export const Switch = React.forwardRef<HTMLInputElement, SwitchProps>(({ name, onBlur, onChange, value = false, label }, ref) => (
    <StyledSwitch
        ref={ref}
        checked={value}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        label={
            <Text size={FontSize._14} weight={FontWeight._700}>
                {label}
            </Text>
        }
    />
));

const StyledSwitch = styled(MtSwitch)`
    .mantine-Switch-input {
        background-color: ${({ theme }) => theme.color.gray25};
        &:checked {
            background-color: ${({ theme }) => theme.color.azure};
            border-color: ${({ theme }) => theme.color.azure};
        }
    }
`;
