import { LinkButton, Text } from "components";
import { FC, useCallback } from "react";

import { useExtendSubscriptionMutation, useGetCsrfTokenQuery } from "src/api";
import { showSuccessNotification } from "src/notifications";
import { Trans, useTranslation } from "src/translations";
import { useGetExpirationDate } from "../utils";

export const ExtendSubscription: FC = () => {
    const { t } = useTranslation();

    const { daysToExpiration, expirationDate } = useGetExpirationDate();

    const { data: csrfTokenResponse } = useGetCsrfTokenQuery();
    const [extend] = useExtendSubscriptionMutation();
    const extendSubscription = useCallback(async () => {
        try {
            await extend(csrfTokenResponse!.token!).unwrap();
            showSuccessNotification({
                title: t("subscriptions.extend.successTitle"),
                message: t("subscriptions.extend.successMessage"),
            });
        } catch (e) {
            // handled by global error handling
        }
    }, [csrfTokenResponse, extend, t]);

    return (
        <div>
            <Trans i18nKey="subscriptions.extend.description" values={{ daysToExpiration, expirationDate }}>
                <Text />
                <LinkButton onClick={extendSubscription} />
                <Text />
            </Trans>
        </div>
    );
};
