import differenceInDays from "date-fns/differenceInDays";
import { useMemo } from "react";

import { useGetAuthenticatedUserQuery, useGetConfigQuery } from "src/api";
import { DATE_FORMAT, localeFormat } from "src/utils";

export const useGetExpirationDate = () => {
    const { data: user } = useGetAuthenticatedUserQuery();
    const { data: config } = useGetConfigQuery();

    return useMemo(() => {
        if (!user || !config) {
            return { daysToExpiration: "", expirationDate: "" };
        }

        const today = new Date(config.currentDateTime);
        const expirationDate = new Date(user.subscriptionExpiration!);

        return { daysToExpiration: differenceInDays(expirationDate, today), expirationDate: localeFormat(expirationDate, DATE_FORMAT) };
    }, [config, user]);
};
