import type { FC } from "react";
import styled from "styled-components";

import { Color, FontSize, FontWeight } from "src/theme";
import { createInterval } from "src/utils";
import { Skeleton } from "../Skeleton";

type TextProps = Readonly<{
    color?: Color;
    size?: FontSize;
    weight?: FontWeight;
    wordBreak?: "normal" | "break-word";
    expectedRows?: number;
    skeletonWidth?: string;
    isLoading?: boolean;
}>;

const Text: FC<TextProps> = ({
    children,
    color = Color.textPrimary,
    size = FontSize._16,
    weight = FontWeight._400,
    wordBreak = "break-word",
    expectedRows = 1,
    skeletonWidth = "20rem",
    isLoading = false,
}) => {
    if (isLoading) {
        return (
            <>
                {createInterval(expectedRows).map((key) => (
                    <Skeleton key={key} height="1.2rem" width={skeletonWidth} />
                ))}
            </>
        );
    }

    return (
        <StyledText color={color} size={size} weight={weight} wordBreak={wordBreak}>
            {children}
        </StyledText>
    );
};

const StyledText = styled.span<TextProps>`
    color: ${({ color }) => color};
    font-size: ${({ size }) => size};
    font-weight: ${({ weight }) => weight};
    word-break: ${({ wordBreak }) => wordBreak};
`;

export default Text;
