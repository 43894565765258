import type { FC } from "react";
import { useNavigate } from "react-router-dom";

import { useGetOutageDetailQuery } from "src/api";
import { routes } from "src/app/routes";
import {
    Button,
    ButtonVariant,
    Flex,
    GridElement,
    Heading,
    OutageDetailLayout,
    OutageInfoAreas,
    OutageInfoLayout,
    OutagePeriodAreas,
    OutagePeriodLayout,
    Skeleton,
} from "src/components";
import { useTranslation } from "src/translations";
import { DATE_TIME_FORMAT, localeFormat } from "src/utils";
import { LabelSystemsPair, LabelValuePair, Status, Type } from "./containers";

export const OutageDetail: FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const { outageId } = routes.outageDetail.useParams();
    const { data: detail, isLoading } = useGetOutageDetailQuery(outageId!);

    const start = detail?.start ? new Date(detail.start) : undefined;
    const expectedEnd = detail?.expectedEnd ? new Date(detail.expectedEnd) : undefined;
    const end = detail?.end ? new Date(detail.end) : undefined;

    return (
        <>
            <Heading level={2}>{t("outageDetail.title")}</Heading>
            <OutageDetailLayout>
                {isLoading ? <Skeleton /> : <Heading level={3}>{detail?.summary}</Heading>}

                <Flex flexDirection="column" gap="5rem">
                    <OutageInfoLayout>
                        <GridElement gridArea={OutageInfoAreas.status}>
                            <Status outageState={detail?.issueState} isLoading={isLoading} />
                        </GridElement>
                        <GridElement gridArea={OutageInfoAreas.type}>
                            <Type type={detail?.category} isLoading={isLoading} />
                        </GridElement>
                        <GridElement gridArea={OutageInfoAreas.description}>
                            <LabelValuePair label={t("outageDetail.description")} value={detail?.description} isLoading={isLoading} />
                        </GridElement>
                    </OutageInfoLayout>

                    <Flex flexDirection="column" gap="1.5rem">
                        <OutagePeriodLayout withGap={!!end}>
                            <GridElement gridArea={OutagePeriodAreas.start}>
                                <LabelValuePair
                                    label={t("outageDetail.start")}
                                    value={localeFormat(start, DATE_TIME_FORMAT)}
                                    isLoading={isLoading}
                                />
                            </GridElement>
                            <GridElement gridArea={OutagePeriodAreas.expectedEnd}>
                                <LabelValuePair
                                    label={t("outageDetail.expectedEnd")}
                                    value={localeFormat(expectedEnd, DATE_TIME_FORMAT)}
                                    isLoading={isLoading}
                                />
                            </GridElement>
                            <GridElement gridArea={OutagePeriodAreas.end}>
                                {end && <LabelValuePair label={t("outageDetail.end")} value={localeFormat(end, DATE_TIME_FORMAT)} />}
                            </GridElement>
                        </OutagePeriodLayout>
                        <LabelSystemsPair label={t("outageDetail.mainSystems")} systems={detail?.mainSystems || []} isLoading={isLoading} />
                        <LabelSystemsPair
                            label={t("outageDetail.otherSystems")}
                            systems={detail?.otherSystems || []}
                            isLoading={isLoading}
                        />
                    </Flex>
                </Flex>
                <Button onClick={() => navigate(routes.dashboard.path)} variant={ButtonVariant.Filled}>
                    {t("back")}
                </Button>
            </OutageDetailLayout>
        </>
    );
};
