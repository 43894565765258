import addDays from "date-fns/addDays";
import addWeeks from "date-fns/addWeeks";
import endOfWeek from "date-fns/endOfWeek";
import format from "date-fns/format";
import formatWithOptions from "date-fns/fp/formatWithOptions";
import { cs } from "date-fns/locale";
import startOfWeek from "date-fns/startOfWeek";

export const DATE_FORMAT = "d. M. yyyy";
export const DATE_TIME_FORMAT = `${DATE_FORMAT} H:mm`;
export const BE_DATE_FORMAT = "yyyy-MM-dd";

export const localeFormat = (date: Date | undefined, formatStr: string) => {
    if (!date) {
        return "";
    }

    return format(date, formatStr, { locale: cs });
};
export const getCurrentYear = (): string => localeFormat(new Date(), "yyyy");
export const getOffsetDate = (date: Date, dayOffset = 0, weekOffset = 0): Date => addWeeks(addDays(date, dayOffset), weekOffset);
export const getStartOfWeek = (date: Date) => startOfWeek(date, { locale: cs });
export const getEndOfWeek = (date: Date) => endOfWeek(date, { locale: cs });

export const dateToString = formatWithOptions({ locale: cs }, BE_DATE_FORMAT);
