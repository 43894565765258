import { showNotification } from "@mantine/notifications";

import { Color } from "src/theme";

type NotificationSettings = {
    title: string;
    message: string;
};

export const showErrorNotification = (settings: NotificationSettings) => {
    showNotification({
        title: settings.title,
        message: settings.message,
        styles: () => ({
            root: {
                "&::before": { backgroundColor: Color.red100 },
            },
        }),
    });
};
