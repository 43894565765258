export enum Color {
    white = "#FFFFFF",
    green = "#03A167",

    yellow100 = "#F5A623",
    yellow10 = "#FEf6E8",

    red100 = "#D71C4F",
    red10 = "#FBE7EC",

    azure = "#005CA9",
    saphire = "#3888D6",

    blue10 = "#EEF2FA",
    blue200 = "#004E8F",

    gray10 = "#F5F5FA",
    gray25 = "#ECEDEE",
    gray100 = "#D0D4DA",
    gray200 = "#617384",

    textPrimary = "#1B1B1B",
    textSecondary = "#617384",
}
