import type { FC } from "react";

import { Badge, Flex, Skeleton, Text } from "src/components";
import { FontWeight } from "src/theme";

type LabelSystemsPairProps = {
    label: string;
    systems: string[];
    isLoading?: boolean;
};

export const LabelSystemsPair: FC<LabelSystemsPairProps> = ({ label, systems, isLoading }) => {
    if (!isLoading && systems.length === 0) {
        return null;
    }

    return (
        <Flex flexDirection="column" gap="0.5rem">
            <Text weight={FontWeight._700}>{label}</Text>
            {isLoading ? (
                <Skeleton />
            ) : (
                <Flex gap="0.3rem">
                    {systems.map((system) => (
                        <Badge key={system} text={system} />
                    ))}
                </Flex>
            )}
        </Flex>
    );
};
