import type { FC } from "react";
import styled from "styled-components";

import { UnstyledButton } from "../Button";

type IconProps = Readonly<{
    icon: string;
    height: number;
    width: number;
    alt?: string;
    onClick?: () => void;
    withAnimation?: boolean;
}>;

export const Icon: FC<IconProps> = ({ icon, height, width, alt, onClick, withAnimation }) => {
    const IconComponent = <StyledIcon src={icon} height={height} width={width} alt={alt} withAnimation={withAnimation} />;

    return onClick ? <UnstyledButton onClick={onClick}>{IconComponent}</UnstyledButton> : IconComponent;
};

const StyledIcon = styled.img<Pick<IconProps, "withAnimation">>`
    transition: ${({ withAnimation }) => withAnimation && "all .3s ease-in-out"};
    :hover {
        transform: ${({ withAnimation }) => withAnimation && "scale(1.1)"};
    }
`;
