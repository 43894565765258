import type { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { routes } from "src/app/routes";
import { Color, FontSize, FontWeight } from "src/theme";
import { useTranslation } from "src/translations";
import { OutageDto, OutageState, OutageType } from "src/types";
import { DATE_TIME_FORMAT, localeFormat } from "src/utils";
import { Flex } from "../Layouts";
import { Text } from "../Text";
import { Tooltip } from "../Tooltip";

type OutageAlertProps = {
    outage: OutageDto;
};

type AlertProps = {
    clickable: boolean;
    type: OutageType;
};

const TooltipContent: FC<{ outage: OutageDto }> = ({ outage }) => {
    const { t } = useTranslation();
    const { start, end, expectedEnd } = outage;

    return (
        <Flex flexDirection="column">
            <Text color={Color.white} size={FontSize._14} weight={FontWeight._700}>
                {outage.summary}
            </Text>
            <Text color={Color.white} size={FontSize._14}>{`${t("outage.start")}: ${localeFormat(
                new Date(start),
                DATE_TIME_FORMAT,
            )}`}</Text>
            {outage.issueState === OutageState.Open ? (
                <Text color={Color.white} size={FontSize._14}>{`${t("outage.expectedEnd")}: ${localeFormat(
                    new Date(expectedEnd),
                    DATE_TIME_FORMAT,
                )}`}</Text>
            ) : (
                <EndDate endTime={end} />
            )}
        </Flex>
    );
};

const EndDate: FC<{ endTime: string | undefined }> = ({ endTime }) => {
    const { t } = useTranslation();

    if (!endTime) {
        return null;
    }

    return (
        <Text color={Color.white} size={FontSize._14}>{`${t("outage.end")}: ${localeFormat(new Date(endTime), DATE_TIME_FORMAT)}`}</Text>
    );
};

export const Alert: FC<AlertProps> = ({ clickable, type }) => <OutageAlertWrapper clickable={clickable} type={type} />;

export const OutageAlert: FC<OutageAlertProps> = ({ outage }) => (
    <Tooltip content={<TooltipContent outage={outage} />}>
        <Link to={routes.outageDetail.fillPathParams({ outageId: outage.id!.toString() })}>
            <Alert clickable type={outage.category} />
        </Link>
    </Tooltip>
);

const OutageAlertWrapper = styled.div<AlertProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: ${({ theme }) => theme.borderRadius._5};
    background-color: ${({ theme, type }) => (type === OutageType.PlannedOutage ? theme.color.yellow100 : theme.color.red100)};
    cursor: ${({ clickable }) => (clickable ? "pointer" : "unset")};
`;
