import type { FC } from "react";
import styled from "styled-components";

import { Color, FontSize, FontWeight } from "src/theme";
import { Text } from "../Text";

export enum BadgeType {
    light = "light",
    neutral = "neutral",
    warning = "warning",
}

type BadgeProps = {
    text: string;
    type?: BadgeType;
};

const getTextColor = (type: BadgeType): Color => {
    switch (type) {
        case BadgeType.light:
            return Color.azure;
        case BadgeType.neutral:
            return Color.gray200;
        default:
            return Color.red100;
    }
};

export const Badge: FC<BadgeProps> = ({ text, type = BadgeType.light }) => (
    <StyledBadge type={type}>
        <Text size={FontSize._14} color={getTextColor(type)} weight={FontWeight._700}>
            {text}
        </Text>
    </StyledBadge>
);

const StyledBadge = styled.span<Pick<BadgeProps, "type">>`
    display: flex;
    align-self: flex-start;
    padding: 0.3rem 0.5rem;
    background-color: ${({ type }) => {
        switch (type) {
            case BadgeType.light:
                return Color.white;
            case BadgeType.neutral:
                return Color.gray10;
            default:
                return Color.red10;
        }
    }};
    border-width: 1px;
    border-style: solid;
    border-radius: ${({ theme }) => theme.borderRadius._12};
    border-color: ${({ type }) => {
        switch (type) {
            case BadgeType.light:
                return Color.azure;
            case BadgeType.neutral:
                return Color.gray10;
            default:
                return Color.red10;
        }
    }};
`;
